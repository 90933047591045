<template>
<v-container fluid>
    <v-row dense>
        <v-col cols="12" md="12">
            <v-card :elevation="1" color="#dcfa3b2b">
                <v-card-text class="py-1">
                    <v-row dense>
                        <v-col cols="12" md="2">
                            <v-combobox
                                label="Department"
                                dense
                                outlined
                                hide-details
                                :items="$store.getters['department/departments']"
                                v-model="department"
                                item-text="name"
                                item-value="id"
                                :loading="$store.getters['department/loadingDepartments']"
                                @focus="$store.dispatch('department/getDepartments')"
                                class="custom-form"
                            ></v-combobox>
                        </v-col>
                        
                        <v-col cols="12" md="2">
                            <v-combobox
                                label="Designation"
                                dense
                                outlined
                                hide-details
                                :items="$store.getters['designation/designations']"
                                v-model="designation"
                                item-text="name"
                                item-value="id"
                                :loading="$store.getters['designation/loadingDesignations']"
                                @focus="$store.dispatch('designation/getDesignations')"
                                class="custom-form"
                            ></v-combobox>
                        </v-col>
                        
                        <v-col cols="12" md="2">
                            <v-combobox
                                label="Shift"
                                dense
                                outlined
                                hide-details
                                :items="$store.getters['shift/shifts']"
                                v-model="shift"
                                item-text="name"
                                item-value="id"
                                :loading="$store.getters['shift/loadingShifts']"
                                @focus="$store.dispatch('shift/getShifts')"
                                class="custom-form"
                            ></v-combobox>
                        </v-col>
                       
                        <v-col cols="6" md="1">
                            <v-menu
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        dense
                                        outlined
                                        hide-details
                                        v-model="date_from"
                                        label="Date From"
                                        v-on="on"
                                        class="custom-form"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="date_from"></v-date-picker>
                            </v-menu>
                        </v-col>
                        
                        <v-col cols="6" md="1">
                            <v-menu
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        dense
                                        outlined
                                        hide-details
                                        v-model="date_to"
                                        label="Date To"
                                        v-on="on"
                                        class="custom-form"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="date_to"></v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="6" md="1">
                            <v-btn
                                elevation="1"
                                small
                                color="primary"
                                dark
                                style="margin-top:-2px"
                                :loading="loading"
                                @click="getReports"
                            > Search </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row v-if="records.departments && records.departments.length > 0">
        <v-col cols="12">
            <v-btn
                color="primary"
                class="white--text"
                small
                dense
                @click="print"
            >
                <v-icon left small dense> mdi-printer </v-icon>
                Print
            </v-btn>
        </v-col>
        <v-col cols="12" id="reportContent">
            <v-simple-table dense class="record_table">
                <template v-for="department in records.departments">
                    <tr>
                        <th colspan="16" style="text-align: left;color: #0277bd;">{{department.name}}</th>
                    </tr>

                    <tr class="record_heading_tr">
                        <th>SL.</th>
                        <th>E-ID</th>
                        <th>Bio-ID</th>
                        <th>Name</th>
                        <th>Designation</th>
                        <th>T. Days</th>
                        <th>W. Days</th>
                        <th>P</th>
                        <th>L</th>
                        <th>LA</th>
                        <th>A</th>
                        <th>LV</th>
                        <th>LWP</th>
                        <th>H</th>
                        <th>WH</th>
                        <th>OT</th>
                    </tr>

                    <tr v-for="attendance in department.attendances" :key="attendance.sl">
                        <td>{{attendance.sl}}</td>
                        <td>{{attendance.emp_id}}</td>
                        <td>{{attendance.biometric_id}}</td>
                        <td style="text-align: left;">{{attendance.employee_name}}</td>
                        <td style="text-align: left;">{{attendance.designation_name}}</td>
                        <td>{{records.total_days}}</td>
                        <td>{{records.week_days}}</td>
                        <td>{{attendance.present}}</td>
                        <td>{{attendance.late}}</td>
                        <td>{{attendance.late_absent}}</td>
                        <td>{{attendance.absent}}</td>
                        <td>{{attendance.leave}}</td>
                        <td>{{attendance.leave_without_pay}}</td>
                        <td>{{records.holidays}}</td>
                        <td>{{records.weekends}}</td>
                        <td>{{attendance.total_ot}}</td>
                    </tr>
                </template>
            </v-simple-table>
        </v-col>
    </v-row>
</v-container>
    
</template>
<script>
    import utility from '../../mixins/utility.mixin';
    export default {
        mixins: [utility],
        data () {
            return {
                date_from: this.toISOLocal().substr(0, 10),
                date_to: this.toISOLocal().substr(0, 10),
                department: null,
                designation: null,
                shift: null,
                records: [],
                loading: false
            }
        },
        methods: {
            async getReports(){
                this.records = [];
                this.loading = true;

                this.records = await this.$store
                .dispatch('attendance/getAttendanceSummaryReports', {
                    apiParams: {
                        departmentId    : this.department?.id ?? null,
                        designationId   : this.designation?.id ?? null,
                        shiftId         : this.shift?.id ?? null,
                        date_from       : this.date_from,
                        date_to         : this.date_to,
                    },
                    options: {
                        returnData : true
                    }
                });

                this.loading = false;
            },
            async print(){
                let date_text = '('+this.date_from+' to '+this.date_to+')';
                let title = 'Attendance Summary Report ';
                title += date_text;

                let company_profile = this.$store.getters['company/company'];

                // Get HTML to print from element
                const prtHtml = document.getElementById('reportContent').innerHTML;

                // Get all stylesheets HTML
                let stylesHtml = '';
                for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                    stylesHtml += node.outerHTML;
                }

                // Open the print window
                // const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
                const WinPrint = window.open('', 'PRINT', `height=${screen.height}, width=${screen.width}`);
                WinPrint.document.write(`<!DOCTYPE html>
                <html>
                <head>
                    ${stylesHtml}
                </head>
                <body>
                    <div data-app="true" class="v-application v-application--is-ltr theme--light" id="app">
                        <div class="container container--fluid">
                            <div class="row row--dense">
                                <div class="col-2 px-2">
                                    <img style="width: 100%; height: 50px;" src="${this.$store.state.host+company_profile.logo}">
                                </div>
                                <div class="col-8 px-2 text-center">
                                    <span class="font-weight-black">
                                        ${company_profile.name}
                                    </span>
                                    <br>
                                    <span>${company_profile.address}</span>
                                    <br>
                                    <span>
                                        Email:${company_profile.email}, Contact:${company_profile.phone}
                                    </span>
                                </div>
                                <div class="col-2 px-2">
                                    <img style="width: 100%; height: 50px;" src="${this.$store.state.host+'aritech.png'}">
                                </div>
                                <div class="col-12 px-2 text-center">
                                    <p class="font-weight-black custom_header">
                                        ${title}
                                    </p>
                                </div>
                                <div class="col col-12 px-2">
                                    ${prtHtml}
                                </div>
                            </div>
                        </div>
                    </div>
                </body>
                </html>`);

                WinPrint.document.head.innerHTML += `
                    <style>
                        .v-data-table__wrapper{
                            overflow: unset;
                        }
                        .record_heading_tr th{
                            text-align: center !important;
                        }
                        .custom_header{
                            background-color: #0000001f;
                            border-radius: 4px;
                            margin-bottom: 5px !important;
                        }
                        tr:hover{
                            background: unset !important;
                        }
                        .record_heading_tr:hover{
                            background: #4caf7773 !important;
                        }
                        @media print {
                            table.record_table { page-break-after:auto }
                            table.record_table tr    { page-break-inside:avoid; page-break-after:auto }
                            table.record_table td    { page-break-inside:avoid; page-break-after:auto }
                            table.record_table thead { display:table-header-group }
                            table.record_table tfoot { display:table-footer-group }
                        }
                    </style>
				`;

                WinPrint.focus();
                WinPrint.document.close();
                await new Promise(resolve => setTimeout(resolve, 2000));
                WinPrint.print();
                WinPrint.close();
            }

        },

    }
</script>

<style>
    .record_table table, .record_table th, .record_table td {
        border: 1px solid #dee2e6;
        border-collapse: collapse;
        padding: 0 !important;
        
    }
    .record_table th, .record_table td {
        padding: 2px !important;
        font-size: 11px !important;
        text-align: center;
        height: 0 !important;
    }
    .record_heading_tr{
        background: #4caf7773;
    }
</style>